//Wider menu items
.ui-menu {
  width: 15em !important;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

// keep table header from scrolling away
.ui-table-scrollable-header {
  position: -webkit-sticky;
  position: sticky;
  top: $topBarFullHeight;
  z-index: 900;
}

div.p-field {
  margin-bottom: 0rem;
}

.p-dialog .p-dialog-footer {
  background: $ihgDeepBlue;
}

.p-tieredmenu.dropRight .p-menuitem-active>p-tieredmenusub>.p-submenu-list {

  width: 200px !important;
  left: -100% !important;
}

//mixin to add custom icons
@mixin customIcon($name) {
  .pi.#{$name}:before {
    content: '\0000a0\0000a0';
    width: 1em;
    display: inline-block;
    background-image: url(icons/#{$name}.png);
    background-size: 100% 100%;
  }
}

//custom icon definitions

@include customIcon(ihg4);
@include customIcon(ihg11);
@include customIcon(ihg12);
@include customIcon(ihg20);
@include customIcon(ihg25);
@include customIcon(ihg27);
@include customIcon(ihg28);
@include customIcon(ihg29);
@include customIcon(ihg30);
@include customIcon(ihg31);
@include customIcon(ihg33);
@include customIcon(ihg37);
@include customIcon(ihg153);
@include customIcon(ihg178);
@include customIcon(ihg196);


span.lightinlinespinner.p-inputnumber {
  button.p-inputnumber-button.p-button.p-component {
    background: none;
    color: $ihgCoolGrey;
    font-size: 0.25rem;
    padding: 0;
    border: none;
  }

  input.p-inputnumber-input {

    width: 2em;

  }
}


.p-datatable-scrollable-table>.p-datatable-thead {
  z-index: 25 !important;
}

.p-datatable-scrollable-table>.p-datatable-tfoot {
  z-index: 25 !important;
}