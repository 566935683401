/* You can add global styles to this file, and also import other style files */

@import './assets/ihgtheme/_variables';
body {
  margin: 0;
  background: #fff;
}

.p-tooltip {
    pointer-events: none; //ensure tooltips cannot block interface elements (like for drop)
}
